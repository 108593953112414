<dw-dynamic-routing-init></dw-dynamic-routing-init>

<div [hidden]="showSlashScreen" [ngClass]="courseSideBarClass">
    <dw-layout *ngIf="showLayout" [bodyClasses]="themeClasses" hideSideBarMinimizer="true">
        <div logo>

        </div>
        <div breadcrumb>

        </div>
        <div topMenu>
            <div style="width:150px">
                <div class="alert alert-warning env-message" [hidden]="!environmentMessage"
                    style="margin-left:50px; margin-top:12px;display:inline">
                    {{environmentMessage}}
                </div>
            </div>
        </div>
        <div topRight>
            
        </div>
        <div sideBarTop>
            
        </div>
    </dw-layout>
</div>