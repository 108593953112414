// tslint:disable: max-line-length
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AgGridModule } from 'ag-grid-angular';
import { OrderListModule } from 'primeng/orderlist';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppRoutingModule } from './app-routing.module';  

import { DevwareCoreModule,
  DevwareOrmModule,
  DevwareUIModule,
  DevwareAGGridModule, 
  DevwareUILayoutModule,
  DevwareUIMetaDataModule,
  DevwareAdminModule,
  DevwareUiWizardModule, 
  DevwareMediaModule} from '@devwareapps/devware-cap';

import { AppDevwareConfigProviderService } from './app-devware-config-provider.service';


import { AppComponent } from './pages/app/app.component';
import { HomeComponent } from './features/shared/pages/home/home.component';


import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AccordionModule } from 'primeng/accordion';
import { TreeModule } from 'primeng/tree';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuModule } from 'primeng/menu';
import {ProgressBarModule} from 'primeng/progressbar';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import {SliderModule} from 'primeng/slider';
import { DwTaskContainerComponent } from './features/z-devware/tasks/components/dw-task-container/dw-task-container.component';


import {ChartModule} from 'primeng/chart';
import { AppDevwareBootstrapService } from './app-devware-registrations.service';


import {DwUserContextDynamicHandlerToken } from '@devwareapps/devware-cap';
import { DwThemePropertyEditorComponent } from './features/z-devware/components/dw-theme-property-editor/dw-theme-property-editor.component';
import { DwMediaSelector2Component } from './features/z-devware/components/dw-media-selector2/dw-media-selector2.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DwTaskContainerComponent, 
    //AviatorOnlineHomePageComponent,
   
    DwThemePropertyEditorComponent,
    DwMediaSelector2Component,
  ], 
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OrderListModule, 
    InputSwitchModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    CheckboxModule,
    TreeModule,
    SliderModule,
    
    HttpClientModule,
    AgGridModule.forRoot(),
    BsDropdownModule.forRoot(),
    ChartModule,
    
    DevwareCoreModule, 
    DevwareOrmModule,
    DevwareUIModule, 
    DevwareAGGridModule,
    DevwareUILayoutModule,
    DevwareUIMetaDataModule,
    DevwareUiWizardModule,
    DevwareAdminModule, 
    DevwareMediaModule,
    ProgressBarModule,
    AppRoutingModule,
  ],
  providers: [ 
    AppDevwareConfigProviderService,
    AppDevwareBootstrapService,
   
    // {
    //   provide: DwUserContextDynamicHandlerToken,
    //   useClass:AviatorUserContextCustomizerService
    // }
  ],
  bootstrap: [AppComponent], 
  exports: [
    //UserRegistrationComponent
  ]
})
export class AppModule { }
