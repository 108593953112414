import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DwAuthGuardService, DwLoginComponent, DwRouteGuardUtil, DwSsoLoginComponent, UserProfileComponent, DwUserRegistrationComponent, DevwareGetStartedComponent } from '@devwareapps/devware-cap';
import { HomeComponent } from './features/shared/pages/home/home.component';
//import { AviatorOnlineHomePageComponent } from './features/homepage/components/aviator-online-home-page/aviator-online-home-page.component';

var routes = [
    {
        path: '',
        component: HomeComponent,
        data: {
            display: 'Home',
            iconCssClass: 'fa fa-menu-icon fa-home',
            routeOrder: 5,
            hideBreadcrumbParent: true,
            excludeFromMainMenu: false,
            showInMenu: true,
            highlightOnExactMatch: true,
            referenceKey: 'home',
        }
    },  
    {
        path: 'login',
        component: DwLoginComponent,
        data: {
            routeId: 'login',
            display: 'Login',
            iconCssClass: 'fa fa-menu-icon fa-power-off',
            showInMenu: false,
            referenceKey: 'login'
        }
    },
    {
        path: 'register',
        component: DwUserRegistrationComponent,
        //canActivate: [DwAuthGuardService],
        data: {
            routeId: 'register',
            display: 'Register',
            showInMenu: false,
            routeOrder: 16,
            referenceKey: 'register',
        }
    },
    {
        path: "**",
        redirectTo: '',
        data: { 
            showInMenu: false
        }
    },
];


// Add Dw Auth Guard to all routes (uses the requireAuthentication and CanAcessPermission data properties)
DwRouteGuardUtil.secureRoutes(routes, DwAuthGuardService);

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
